@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Black.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Bold.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('/public/assets/fonts/Lato/Lato-Hairline.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Heavy.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Italic.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Light.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Medium.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Semibold.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Lato';
  src: url('/public/assets/fonts/Lato/Lato-Thin.woff2') format('woff2'),
    url('/public/assets/fonts/Lato/Lato-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-Black.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-Light.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-MediumItalic.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-UltraLight.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-BlackItalic.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-Thin.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-Heavy.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-SemiBold.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/public/assets/fonts/gilroy/Gilroy-Bold.woff2') format('woff2'),
    url('/public/assets/fonts/gilroy/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}




* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Gilroy' !important;
  overflow-x: hidden;
}

.form-check-input{
  cursor: pointer;
}

.backdrop {
  position: absolute;
  top: 0;
  background-color: #000000;
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10001;
}

.modal-backdrop.show{
  z-index: -1 !important;
  opacity: 0 !important;
}

div {
  scrollbar-width: none;
}
