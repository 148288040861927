/* Sidebar css start */

.left_sidebar .brand_heading {
  gap: 16px;
  padding: 20px 0px;
}

.left_sidebar .school_name {
  margin: 0;
  width: 70%;
  text-align: center;
  color: #202020;
  background-color: white;
  padding: 11px 30px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Lato';
  line-height: 21.6px;
  border-radius: 30px;
}

.left_sidebar .nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.left_sidebar .heading_left_sidebar_link {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.5px;
}

.left_sidebar .nav-item {
  width: 100%;
  padding: 16px 0px 16px 25px !important;
}

.left_sidebar .nav-tabs {
  border: none !important;
}

.left_sidebar .navlink_image {
  width: 28px;
  height: 28px;
}

.left_sidebar .nav-tabs .nav-link {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px;
  color: white !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 24px;
  padding: 0px;
  /* width: max-content; */
}

.left_sidebar .nav-tabs .nav-item.active {
  background-color: #454545 !important;
}

.left_sidebar .poweredby_qualify {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 282px 0px 70px 0px;
}

.left_sidebar .powered_by_qual {
  display: block;
  margin: auto;
  /* padding: 282px 0px 0px 0px; */
  position: absolute;
  bottom: 70px;
}

/* upgrade_modal */
.upgrade_modal .modal-dialog {
  max-width: 596px;
}

.upgrade_modal .modal-header{
  padding: 20px 20px 11px;
  border: none;
}

.upgrade_modal .btn-close{
  background-size: 13px 13px;
  padding: 13px 5px 13px 13px;
  background-position: center;
}

.upgrade_modal .btn-close:focus{
  box-shadow: none;
}

.upgrade_modal .modal-content{
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #4545451A;
  background-color: #FFFFFF;
}

.upgrade_modal .modal-body{
  padding: 0px 60px 60px 60px;
}

.upgrade_modal .modal-body .upgrade_icon img{
  width: 100px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.upgrade_modal .modal-body .upgrade_icon{
  display: flex;
  justify-content: center;
  margin-bottom: 37px;
}

.upgrade_modal .modal-body .upgrade_text{
  color: #202020;
  font-size: 20px;
  line-height: 24.5px;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 77px;
}

.upgrade_modal .modal-body .upgrade_text a{
  color: #0D47A1;
  text-decoration: none;
  font-weight: 600;
}

.upgrade_modal .modal-footer{
  padding: 0px;
  justify-content: center;
  gap: 30px;
}

.upgrade_modal .modal-footer .upgrade_now_btn{
  margin: 0px;
  padding: 15px 24px;
  box-shadow: 0px 0px 4px 0px #4545451A;
  background-color: #4285F4;
  border-radius: 8px;
  font-size: 20px;
  line-height: 24.5px;
  font-weight: 600;
  color: #FAFAFA;
  text-transform: uppercase;
}

.upgrade_modal .modal-footer .upgrade_now_btn.upgrade_later_btn{
  border: 1px solid #454545;
  background-color: #FFFFFF;
  color: #454545;
}

@media screen and (max-width: 1440px) {
  
  .upgrade_modal .modal-header{
    padding: 15px 15px 8px;
  }

  .upgrade_modal .btn-close{
    background-size: 10px 10px;
    width: 18px;
    height: 18px;
    padding: 8px 4px 8px 10px;
  }

  .upgrade_modal .modal-dialog{
    width: 447px;
  }

  .upgrade_modal .modal-body{
    padding: 0px 45px 45px;
  }

  .upgrade_modal .modal-body .upgrade_icon{
    margin-bottom: 27px;
  }

  .upgrade_modal .modal-body .upgrade_icon img{
    width: 75px;
  }

  .upgrade_modal .modal-body .upgrade_text{
    font-size: 15px;
    line-height: 18.38px;
    margin-bottom: 59px;
  }

  .upgrade_modal .modal-footer .upgrade_now_btn{
    padding: 12px 18px;
    font-size: 15px;
    line-height: 18.38px;
  }
  

}

@media screen and (max-width: 1600px) {

  .left_sidebar .heading_left_sidebar_link {
    font-size: 18px;
    line-height: 20px;
  }

}


@media screen and (max-width:1440px) {

  .left_sidebar {
    width: 17.8%;
    height: auto;
  }

  .left_sidebar .brand_heading {
    gap: 12px;
    padding: 15px 0px;
  }

  .left_sidebar .nav-tabs .nav-link {
    gap: 18px;
  }

  .school_logo_image {
    width: 33px;
  }

  .left_sidebar .heading_left_sidebar_link {
    font-size: 15px;
    line-height: 18.38px;
  }

  .left_sidebar .school_name {
    padding: 8px 22px;
    width: auto;
    white-space: nowrap;
    font-size: 13.5px;
    line-height: 16.2px;
  }

  .left_sidebar .navlink_image {
    width: 21px;
    height: 21px;
  }

  .left_sidebar .nav-tabs .nav-item {
    padding: 13px 0px 13px 24px !important;
    
  }

  .left_sidebar .poweredby_qualify {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 211px 0px 52px 0px;
  }
}

@media screen and (max-width: 1350px) {

  .left_sidebar {
    width: 19.5%;
    height: auto;
  }

}

@media screen and (max-width: 1200px) {

  .left_sidebar {
    width: 22%;
    height: auto;
  }

}

@media screen and (max-width: 1070px) {

  .school_logo_image {
    width: 29px;
  }

  .left_sidebar .brand_heading {
    gap: 10px;
  }

  .left_sidebar .school_name {
    padding: 6px 16px;
  }

  .left_sidebar .nav-tabs .nav-link {
    gap: 14px;
  }

  .left_sidebar .nav-tabs .nav-item {
    padding: 13px 0px 13px 18px !important;
  }

  .left_sidebar .navlink_image {
    width: 18px;
    height: 18px;
  }

  .left_sidebar .heading_left_sidebar_link {
    font-size: 15px;
    line-height: 18.38px;
  }

}

@media screen and (max-width:992px) {

  .left_sidebar {
    display: none;
  }

  .school_logo_image {
    width: 33px;
  }

  .left_sidebar .heading_left_sidebar_link {
    font-size: 15px;
    line-height: 18.38px;
  }

  .left_sidebar .school_name {
    padding: 8px 22px;
    width: auto;
    white-space: nowrap;
    font-size: 13.5px;
    line-height: 16.2px;
  }

  .left_sidebar .brand_heading {
    gap: 12px;
    padding: 15px 0px;
  }

  .left_sidebar .nav-tabs .nav-item {
    padding: 13px 0px 13px 18px !important;
  }

  .left_sidebar .navlink_image {
    width: 21px;
    height: 21px;
  }

  .main_header {
    margin-left: 0px;
    transition: all 0.5s;
  }

  .left_sidebar .nav-tabs .nav-link {
    gap: 18px;
  }

  .homepage_container .row .homepage_tab_content {
    width: 100% !important;
  }

}

/* Sidebar css over */
