/* Hopmepage css start */

.homepage_container .row {
  align-items: flex-start;
}

.homepage_container .row .homepage_tab_content {
  width: calc(100% - 17.813%);
  margin-left: auto;
  overflow: auto;
  background-color: #F2F2F2;
}

.homepage_container .row .homepage_tab_content::-webkit-scrollbar {
  display: none;
}

.left_sidebar {
  background-color: #202020;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 1000;
}

@media screen and (min-width:1441px) {
  .left_sidebar {
    width: 342px;
  }

  .homepage_container .row .homepage_tab_content {
    width: calc(100% - 342px);
    margin-left: auto;
    overflow: auto;
    background-color: #F2F2F2;
  }
}

@media screen and (max-width:1440px) {

  .homepage_container .row .homepage_tab_content {
    width: calc(100% - 17.8%);
    margin-left: auto;
    overflow: auto;
    background-color: #F2F2F2;
  }

}

@media screen and (max-height:1472px) {
  .left_sidebar {
    display: block;
    overflow: auto;
  }

  .left_sidebar::-webkit-scrollbar {
    display: none;
  }

  .left_sidebar .powered_by_qual {
    position: static !important;
  }
}


/* @media screen and (max-width:1400px) {
  .left_sidebar {
    width: 25%;
  }

} */

@media screen and (max-width: 1350px) {

  .homepage_container .row .homepage_tab_content {
    width: calc(100% - 19.5%);
  }

}

@media screen and (max-width: 1200px) {

  .homepage_container .row .homepage_tab_content {
    width: calc(100% - 22%);
  }

}

@media screen and (max-width:992px) {
  .left_sidebar {
    display: none;
  }

  .homepage_container .row .homepage_tab_content {
    margin-left: 0%;
  }

  .dashboard_container {
    margin-left: 0% !important;
  }

  .main_header {
    margin-left: 0px;
    transition: all 0.5s;
  }

  .homepage_container .row .homepage_tab_content {
    width: 100% !important;
  }
}

/* Homepage css over */
